import React, { lazy, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ShareModal from "../../ShareModal";

const SpotifyModal = lazy(() => import("./SpotifyModal"));
const PodcastsAndVideosHeader = lazy(() => import("../podcastsAndVideoHeader"));

export const Podcasts = () => {
  const [podList, setPodList] = useState([]);
  const [selectedPodcastUri, setSelectedPodcastUri] = useState(null);
  const [isPodcastModalOpen, setIsPodcastModalOpen] = useState(false);
  const openPodcastModal = () => setIsPodcastModalOpen(true);
  const closePodcastModal = () => setIsPodcastModalOpen(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const openShareModal = () => setIsShareModalOpen(true);
  const closeShareModal = () => setIsShareModalOpen(false);
  const goBack = () => (location.href = "/media");

  const xlocation = useLocation();
  const queryParams = new URLSearchParams(xlocation.search);
  const podId = queryParams.get("id");

  const podcastsToFetchCount = 32;
  const [offset, setOffset] = useState(0);

  function decodeHtmlEntities(str) {
    var textArea = document.createElement("textarea");
    textArea.innerHTML = str;
    return textArea.value;
  }

  const handleLoadMore = () => {
    fetchPodcasts();
  };

  async function fetchPodcasts() {
    try {
      const res = await fetch(
        `${process.env.API_URL}/podcasts/${podcastsToFetchCount}/${offset}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY,
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        const fetchedPodcasts = [];
        data.items.forEach((podcast) => {
          let title = decodeHtmlEntities(podcast.name);
          let media = podcast.images[1].url;
          let bigmedia = podcast.images[0].url;
          const dateObject = new Date(podcast.release_date);
          let dte = dateObject.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "long",
          });
          let link = podcast.type + "/" + podcast.id;
          fetchedPodcasts.push({
            title: title,
            media: media,
            bigmedia: bigmedia,
            date: dte,
            link: link,
          });
        });
        setPodList(podList.concat(fetchedPodcasts));
        setOffset(offset + fetchedPodcasts.length);

        // Check for podId after setting the podcast list
        if (podId) {
          handlePodcastClick("episode/" + podId);
        }
      } else {
        console.error("Failed to fetch podcasts:", res.status);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchPodcasts();
  }, []);

  useEffect(() => {
    const updatePageTitle = () => {
      document.title = "Podcasts | Cricket8";
    };
    updatePageTitle();
    return () => {};
  }, []);

  const handlePodcastClick = (uri) => {
    setSelectedPodcastUri(uri);
    openPodcastModal();
  };

  return (
    <div
      id="content"
      className="min-h-[75vh] mt-[0px] bg-header content-t overflow-hidden"
    >
      <PodcastsAndVideosHeader
        goBack={goBack}
        openShareModal={openShareModal}
        headerTitle={"Podcasts"}
      />
      <div className="flex flex-col">
        <div className="mt-[30px] w-full grid grid-cols-2 md:flex md:flex-wrap lg:flex lg:flex-wrap">
          {podList.map((pod, index) => (
            <div
              key={index}
              className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-[10px] cursor-pointer"
              onClick={() => handlePodcastClick(pod.link)}
            >
              <div className="w-full  h-[40px] flex flex-row justify-between items-center bg-[#4b3265] text-white text-[14px]">
                <div className="w-[64px] h-[40px] bg-lime text-main flex justify-center items-center">
                  Podcast
                </div>
                <div className="font-chakra text-[12px] normal-case mr-2">
                  {pod.date}
                </div>
              </div>
              <div className="relative">
                <img
                  src={pod.bigmedia}
                  className="w-[100%] h-auto object-cover"
                />
              </div>
              <div className="w-full h-[72px] text-left text-white text-[18px] p-[10px] flex">
                <div className="text-[14px] lg:text-[16px]">{pod.title}</div>
              </div>
            </div>
          ))}
        </div>
        {podList.length > 0 && (
          <div
            onClick={handleLoadMore}
            className="w-full flex justify-center m-[10px]"
          >
            <div className="mt-4 bg-lime h-[50px] w-[140px] max-w-[430px] text-main flex justify-center items-center">
              LOAD MORE
            </div>
          </div>
        )}
      </div>
      <SpotifyModal
        isOpen={isPodcastModalOpen}
        onRequestClose={() => closePodcastModal()}
        embedUri={selectedPodcastUri}
      />
      <>
        <ShareModal
          isOpen={isShareModalOpen}
          onRequestClose={closeShareModal}
          url={window.location.href}
        />
      </>
    </div>
  );
};
export default Podcasts;
